<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20 space-y-7 w-full">
        <div class="flex items-center justify-between">
          <h1 class="lg:text-3xl text-2xl font-semibold">Sửa bản nhạc</h1>
          <router-link
            class="flex items-center justify-center px-2 py-2 rounded-md bg-green-600 hover:bg-green-700 hover:text-white text-white space-x-1.5"
            :to="{ name: 'AdminSheetList' }"
          >
            <span class="whitespace-nowrap">Danh sách</span>
          </router-link>
        </div>
        <div v-if="message">
          <alert :message="message" :status="status" />
        </div>
        <form v-on:submit.prevent="updateSheet" class="grid grid-cols-1 gap-3">
          <div>
            <label for="title" class="font-semibold">Tên bản nhạc (*)</label>
            <input
              v-model="sheet.title"
              class="shadow-none with-border"
              id="title"
              type="text"
              autocomplete="off"
              required
            />
          </div>
          <div>
            <label for="title" class="font-semibold">Năm sáng tác</label>
            <input
              v-model="sheet.year"
              class="shadow-none with-border"
              id="year"
              type="number"
              autocomplete="off"
            />
          </div>

          <div>
            <label for="content" class="font-semibold">Ảnh các bản nhạc</label>
            <editor :full="true" id="content" v-model:content="sheet.content" />
          </div>

          <div class="flex inline-flex align-items-center">
            <label for="year" class="font-semibold mr-5 mb-0"
              >Kiểu hiển thị lời</label
            >
            <div class="flex inline-flex align-items-center">
              <div class="mr-3 flex inline-flex align-items-center">
                <input
                  type="radio"
                  v-model="sheet.lyric_type"
                  v-bind:value="'Thơ'"
                />
                Thơ
              </div>
              <div class="mr-3 flex inline-flex align-items-center">
                <input
                  type="radio"
                  v-model="sheet.lyric_type"
                  v-bind:value="'Lời: phỏng thơ'"
                />
                Lời: phỏng thơ
              </div>
              <div class="mr-3 flex inline-flex align-items-center">
                <input
                  type="radio"
                  v-model="sheet.lyric_type"
                  v-bind:value="'Lời'"
                />
                Lời
              </div>
              <div class="mr-3 flex inline-flex align-items-center">
                <input
                  type="radio"
                  v-model="sheet.lyric_type"
                  v-bind:value="'Lời Việt'"
                />
                Lời Việt
              </div>
            </div>
          </div>

          <div class="">
            <label class="font-semibold"
              >Tác giả lời
              <i
                class="far fa-question-circle"
                :uk-tooltip="'Chọn tác giả lời trong danh sách'"
              ></i
            ></label>
            <multiselect
              v-model="poets"
              mode="tags"
              valueProp="id"
              label="title"
              trackBy="title"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchPoets"
            >
              <template v-slot:option="{ option }">
                <img
                  class="w-10 h-10 rounded-full object-cover mr-3"
                  :src="
                    option.avatar
                      ? option.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                  "
                />
                {{ option.title }}
                {{ option.yob ? " (" + option.yob + ")" : "" }}
              </template>

              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user">
                  <img
                    :src="
                      option.avatar
                        ? option.avatar.url
                        : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                    "
                    class="w-6 h-6 rounded-full object-cover mr-3"
                  />
                  {{ option.title }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>

          <div class="">
            <div class="keyword-input-container shadow-none">
              <input
                @keyup.enter="addPoetToSheet"
                v-model="poet_title"
                type="text"
                class="keyword-input with-border"
                placeholder="Hoặc thêm tác giả lời mới"
              />
              <button
                @click="addPoetToSheet"
                type="button"
                class="keyword-input-button ripple-effect"
              >
                <i class="icon-feather-plus"></i>
              </button>
            </div>
          </div>

          <div>
            <label for="composer_alias" class="font-semibold"
              >Bút danh khi sáng tác</label
            >
            <input
              v-model="sheet.composer_alias"
              class="shadow-none with-border"
              id="composer_alias"
              type="text"
              autocomplete="off"
            />
          </div>

          <div>
            <label class="font-semibold"
              >Sáng tác
              <i
                class="far fa-question-circle"
                :uk-tooltip="'Chọn tác giả nhạc trong danh sách'"
              ></i
            ></label>
            <multiselect
              v-model="composers"
              mode="tags"
              valueProp="id"
              label="title"
              trackBy="title"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchComposers"
            >
              <template v-slot:option="{ option }">
                <img
                  class="w-10 h-10 rounded-full object-cover mr-3"
                  :src="
                    option.avatar
                      ? option.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                  "
                />
                {{ option.title }}
                {{ option.yob ? " (" + option.yob + ")" : "" }}
              </template>

              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user">
                  <img
                    :src="
                      option.avatar
                        ? option.avatar.url
                        : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                    "
                    class="w-6 h-6 rounded-full object-cover mr-3"
                  />
                  {{ option.title }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>

            <div class="mt-3">
              <div class="keyword-input-container shadow-none">
                <input
                  @keyup.enter="addComposerToSheet"
                  v-model="composer_title"
                  type="text"
                  class="keyword-input with-border"
                  placeholder="Hoặc thêm nhạc sĩ mới"
                />
                <button
                  @click="addComposerToSheet"
                  type="button"
                  class="keyword-input-button ripple-effect"
                >
                  <i class="icon-feather-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <label class="font-semibold"
              >Tag
              <i
                class="far fa-question-circle"
                :uk-tooltip="'Chọn tag trong danh sách'"
              ></i
            ></label>
            <multiselect
              v-model="tags"
              mode="tags"
              valueProp="id"
              label="name"
              trackBy="name"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchTags"
            >
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>

              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag">
                  {{ option.name }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>

            <div class="mt-3">
              <div class="keyword-input-container shadow-none">
                <input
                  @keyup.enter="createTags"
                  v-model="tag_name"
                  type="text"
                  class="keyword-input with-border"
                  placeholder="Hoặc thêm tag mới"
                />
                <button
                  @click="createTags"
                  type="button"
                  class="keyword-input-button ripple-effect"
                >
                  <i class="icon-feather-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <label class="font-semibold">Đóng góp</label>
            <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
            >
              <template v-slot:option="{ option }">
                <img
                  class="w-10 h-10 rounded-full object-cover mr-3"
                  :src="
                    option.avatar
                      ? option.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                  "
                />
                {{ option.username }}
              </template>

              <template v-slot:singlelabel="{ value }">
                <div class="multiselect-single-label">
                  <img
                    class="w-7 h-7 rounded-full object-cover mr-3"
                    :src="
                      value.avatar
                        ? value.avatar.url
                        : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
                    "
                  />
                  {{ value.username }}
                </div>
              </template>
            </multiselect>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button
              :disabled="disabled"
              type="submit"
              class="flex items-center justify-center px-4 py-2 rounded-md bg-green-600 hover:bg-green-700 hover:text-white text-white space-x-1.5"
            >
              Cập nhật
            </button>
          </div>
        </form>
      </div>
    </div>

    <alert :message="message" />
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Editor from "../../Editor";
import ApiService from "../../../core/services/api.service";
import Alert from "../../Alert";
import { showAlert } from "../../../core/services/utils.service";
import Multiselect from "@vueform/multiselect";

export default {
  name: "AdminSheetEdit",
  components: { Alert, Editor, Multiselect },
  data() {
    return {
      id: null,
      composers: [],
      poets: [],
      uploader: {
        id: null,
      },
      sheet: {
        title: "",
        content: "",
        lyric_type: "",
        composer_alias: "",
        year: null,
        uploader_id: null,
      },
      composer_title: "",
      composer: {
        id: "",
        title: "",
      },
      poet_title: "",
      poet: {
        id: "",
        title: "",
      },
      disabled: false,
      status: "",
      message: "",
      tags: [],
      tag_name: "",
    };
  },
  methods: {
    loadSheet() {
      let query = `query($id: ID!) {
        sheet(id: $id) {
          id
          title
          slug
          content
          lyric_type
          composer_alias
          year
          uploader {
            id
            username
            avatar {
              url
            }
          }
          composers(first: 20) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          poets(first: 20) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          tags {
            id
            name
            slug
          }
        }
      }`;

      ApiService.graphql(query, { id: this.id })
        .then(({ data }) => {
          if (data.data && data.data.sheet) {
            this.sheet = data.data.sheet;
            this.uploader = this.sheet.uploader;
            if (this.sheet.composers && this.sheet.composers.data) {
              this.composers = this.sheet.composers.data;
            }
            if (this.sheet.poets && this.sheet.poets.data) {
              this.poets = this.sheet.poets.data;
            }
            if (this.sheet.tags) {
              this.tags = this.sheet.tags;
            }
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadComposers() {
      let query = `query($id: ID!) {
        sheet(id: $id) {
          composers(first: 20) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, { id: this.id })
        .then(({ data }) => {
          if (
            data.data &&
            data.data.sheet &&
            data.data.sheet.composers &&
            data.data.sheet.composers.data
          ) {
            this.composers = data.data.sheet.composers.data;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    createTags() {
      if (!this.tag_name) {
        this.$toast.error("Bạn chưa nhập tên tag");
        return;
      }

      let query = `mutation($tags: String!) {
        createTags(tags: $tags) {
          id
          name
        }
      }`;

      ApiService.graphql(query, { tags: this.tag_name })
        .then(({ data }) => {
          if (data.data && data.data.createTags) {
            this.tag_name = "";
            this.tags = data.data.createTags;
            data.data.createTags.forEach((tag) => {
              var existed = false;
              for (var i = 0; i < this.tags.length; i++) {
                if (this.tags[i].name == tag.name) {
                  existed = true;
                  break;
                }
              }
              if (!existed) {
                this.tags.push(tag);
              }
            });
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    addComposerToSheet() {
      let query = `mutation($sheet_id: ID!, $composer_title: String!) {
        addComposerToSheet(sheet_id: $sheet_id, composer_title: $composer_title) {
          id
        }
      }`;

      ApiService.graphql(query, {
        sheet_id: this.sheet.id,
        composer_title: this.composer_title,
      })
        .then(({ data }) => {
          if (data.data && data.data.addComposerToSheet) {
            this.composer_title = "";
            this.loadComposers();
            this.$toast.success("Thêm nhạc sĩ thành công");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    addPoetToSheet() {
      let query = `mutation($sheet_id: ID!, $poet_title: String!) {
        addPoetToSheet(sheet_id: $sheet_id, poet_title: $poet_title) {
          id
        }
      }`;

      ApiService.graphql(query, {
        sheet_id: this.sheet.id,
        poet_title: this.poet_title,
      })
        .then(({ data }) => {
          if (data.data && data.data.addPoetToSheet) {
            this.poet_title = "";
            this.loadPoets();
            this.$toast.success("Thêm tác giả lời thành công");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    updateSheet() {
      if (!this.sheet.title) {
        this.message = "Bạn chưa nhập tên bản nhạc";
        showAlert();
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $lyric_type: String, $composer_alias: String, $year: Int, $uploader_id: ID, $sync_sheet_composer: [SyncSheetComposer], $sync_sheet_poet: [SyncSheetPoet], $sync_sheet_tag: [SyncSheetTag]) {
        updateSheet(input: {
          id: $id
          title: $title,
          content: $content
          lyric_type: $lyric_type
          composer_alias: $composer_alias
          year: $year
          uploader_id: $uploader_id
          composers: {
            sync: $sync_sheet_composer
          }
          poets: {
            sync: $sync_sheet_poet
          }
          tags: {
            sync: $sync_sheet_tag
          }
        }) {
          id
        }
      }`;

      let sync_sheet_composer = [];
      let sync_sheet_poet = [];
      let sync_sheet_tag = [];
      this.composers.map((item, index) => {
        sync_sheet_composer.push({ id: item.id, order: index });
      });
      this.poets.map((item, index) => {
        sync_sheet_poet.push({ id: item.id, order: index });
      });
      this.tags.map((item) => {
        sync_sheet_tag.push({ id: item.id });
      });

      ApiService.graphql(query, {
        id: this.sheet.id,
        title: this.sheet.title,
        content: this.sheet.content,
        lyric_type: this.sheet.lyric_type,
        composer_alias: this.sheet.composer_alias,
        year: this.sheet.year,
        sync_sheet_composer: sync_sheet_composer,
        sync_sheet_poet: sync_sheet_poet,
        sync_sheet_tag: sync_sheet_tag,
        uploader_id: this.uploader ? this.uploader.id : null,
      })
        .then(({ data }) => {
          if (data.data && data.data.updateSheet) {
            this.$router.push({ name: "AdminSheetList" });
          } else {
            this.status = "error";
            this.message = data.errors[0].message;
            window.scrollTo(0, 0);
            this.disabled = false;
            showAlert();
          }
        })
        .catch((response) => {
          this.status = "error";
          this.message = response.message;
          window.scrollTo(0, 0);
          this.disabled = false;
          showAlert();
        });
    },
    async searchComposers(q) {
      let query = `query($q: Mixed) {
        composers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, { q: `${q}%` })
        .then(({ data }) => {
          if (data.data && data.data.composers && data.data.composers.data) {
            data.data.composers.data.map((item) => {
              item.icon = item.avatar
                ? item.avatar.url
                : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
            });
            return data.data.composers.data;
          } else {
            return [];
          }
        })
        .catch((response) => {
          console.log(response);
          return [];
        });
    },
    async searchPoets(q) {
      let query = `query($q: Mixed) {
        poets(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, { q: `${q}%` })
        .then(({ data }) => {
          if (data.data && data.data.poets && data.data.poets.data) {
            data.data.poets.data.map((item) => {
              item.icon = item.avatar
                ? item.avatar.url
                : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
            });
            return data.data.poets.data;
          } else {
            return [];
          }
        })
        .catch((response) => {
          console.log(response);
          return [];
        });
    },
    async searchTags(q) {
      let query = `query($q: Mixed) {
        tags(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "name", operator: LIKE, value: $q}]}) {
          data {
            id
            name
          }
        }
      }`;

      return await ApiService.graphql(query, { q: `${q}%` })
        .then(({ data }) => {
          if (data.data && data.data.tags && data.data.tags.data) {
            return data.data.tags.data;
          } else {
            return [];
          }
        })
        .catch((response) => {
          console.log(response);
          return [];
        });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, { q: `${q}%` })
        .then(({ data }) => {
          if (data.data && data.data.users && data.data.users.data) {
            data.data.users.data.map((item) => {
              item.icon = item.avatar
                ? item.avatar.url
                : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
            });
            return data.data.users.data;
          } else {
            return [];
          }
        })
        .catch((response) => {
          console.log(response);
          return [];
        });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadSheet();
  },
};
</script>
